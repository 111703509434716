:root {
  --titleContainerWidth: 400px;
  --titleContainerHeight: 55px;
  --titleContainerMarginLeft: 28px;
  --titleContainerMarginTop: 35px;
  --iconContainerSize: 65px;
  --iconContainerLeft: 36px;
  --iconContainerBottom: 24px;
  --titleContentTop: 45px;
  --titleContentLeft: 115px;
  --titleName: 30px;
}
.titleContainer {
  display: inline-block;
  width: var(--titleContainerWidth);
  height: var(--titleContainerHeight);
  transform: skew(38deg);
  margin-left: var(--titleContainerMarginLeft);
  margin-top: var(--titleContainerMarginTop);
}

.iconContainer {
  width: var(--iconContainerSize);
  height: var(--iconContainerSize);
  border-radius: 50%;
  position: absolute;
  left: var(--iconContainerLeft);
  bottom: var(--iconContainerBottom);
  z-index: 1;
  border: 1px solid palevioletred;
}

.iconContainer img {
  width: 100%;
  padding: 10px;
}

.titleContent {
  display: inline;
  text-transform: uppercase;
  position: absolute;
  top: var(--titleContentTop);
  left: var(--titleContentLeft);
  color: white;
  text-shadow: 3px 3px 5px rgb(69, 70, 69);
  font-size: var(--titleName);
}

/* Responsive code start */

/* laptop */

@media screen and (max-width: 1760px) {
  :root {
    --titleContainerWidth: 362px;
    --titleContainerHeight: 55px;
    --titleContainerMarginLeft: 22px;
    --titleContainerMarginTop: 35px;
    --iconContainerSize: 65px;
    --iconContainerLeft: 36px;
    --iconContainerBottom: 24px;
    --titleContentTop: 44px;
    --titleContentLeft: 120px;
  }
}

@media screen and (max-width: 435px) {
  :root {
    --titleContainerWidth: 340px;
  }
}
@media screen and (max-width: 400px) {
  :root {
    --titleContainerWidth: 310px;
  }
}
@media screen and (max-width: 370px) {
  :root {
    --titleContainerWidth: 280px;
    --titleName: 26px;
    --titleContentTop: 49px;
    --titleContentLeft: 115px;
  }
}
@media screen and (max-width: 330px) {
  :root {
    --titleContainerWidth: 242px;
    --titleName: 25px;
    --titleContentLeft: 103px;
  }
}
