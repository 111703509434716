:root {
  --nContainerWidth: 340px;
  --nContainerHeight: 90px;
  --nLeft: 98px;
  --nbottom: 17px;
  --nFrontSize: 2.3rem;
  --nUnderlineWidth: 180px;
  --nMarginLeft: 48px;
}
.nameContainer {
  display: inline-block;
  width: var(--nContainerWidth);
  height: var(--nContainerHeight);
  transform: skew(145deg);
  background: linear-gradient(to right, rgb(225, 174, 54), rgb(199, 30, 48));
  margin-left: var(--nMarginLeft);
  box-shadow: 1px 1px 2px 3px rgb(155, 155, 155);
  /* margin-top: 5px; */
}

.name {
  display: inline-block;
  position: absolute;
  bottom: var(--nbottom);
  left: var(--nLeft);
  text-align: center;
  /* position: relative; */
  /* z-index: 2; */
  /* color: white; */
  /* background-color: red; */
}

.name h1 {
  color: white;
  font-size: var(--nFrontSize);
  text-shadow: 3px 3px 7px rgb(56, 57, 56);
}

.name .underline {
  display: inline-block;
  width: var(--nUnderlineWidth);
  border-top: 3px solid white;
  border-radius: 5px;
}

/* Responsive code start */

/* laptop */

@media screen and (max-width: 1760px) {
  :root {
    --nContainerWidth: 415px;
    --nContainerHeight: 90px;
    --nLeft: 92px;
    --nbottom: 12px;
    --nFrontSize: 3rem;
    --nUnderlineWidth: 239px;
    --nMarginLeft: 36px;
  }
}

/*  Responsive code begin */

@media screen and (max-width: 480px) {
  :root {
    --nContainerWidth: 87%;
    --nContainerHeight: 64px;
    --nLeft: 22%;
    --nbottom: 6px;
    --nFrontSize: 2.3rem;
    --nUnderlineWidth: 180px;
    --nMarginLeft: 27px;
  }
}

@media screen and (max-width: 450px) {
  :root {
    --nFrontSize: 2.1rem
  }
}

@media screen and (max-width: 385px) {
  :root {
    --nContainerWidth: 85%;
    --nContainerHeight: 64px;
    --nLeft: 20%;
    --nbottom: 6px;
    --nFrontSize: 2.1rem;
  }
  .name .underline {
    display: none;
  }
  .name h1 {
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 335px) {
  :root {
    --nContainerWidth: 84%;
    --nContainerHeight: 56px;
    --nLeft: 17%;
    --nbottom: 6px;
    --nFrontSize: 2rem;
  }
  .name h1 {
    margin-bottom: 10px;
  }
}
