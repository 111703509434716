:root {
  --iContainerWidth: 260px;
  --iContainerHeight: 120px;
  --iImgContainerSize: 95px;
  --iTop: 13px;
  --iLeft: 126px;
  --iMarginLeft: 53px;
}
.imageContainer {
  display: inline-block;
  width: var(--iContainerWidth);
  height: var(--iContainerHeight);
  background: linear-gradient(to right, rgb(118, 46, 138), rgb(233, 88, 101));
  transform: skew(145deg);
  margin-left: var(--iMarginLeft);
  box-shadow: 1px 1px 2px 6px rgb(155, 155, 155);
  margin-top: 5px;
  /* border: 2px solid brown; */
}

.dmImg {
  display: inline-block;
  width: var(--iImgContainerSize);
  height: var(--iImgContainerSize);
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  left: var(--iLeft);
  top: var(--iTop);
}

.dmImg img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

/* Responsive code start */

/* laptop */

@media screen and (max-width: 1760px) {
  :root {
    --iContainerWidth: 330px;
    --iContainerHeight: 130px;
    --iImgContainerSize: 100px;
    --iTop: 22px;
    --iLeft: 172px;
    --iMarginLeft: 56px;
  }
}

@media screen and (max-width: 1080px) {
  :root {
    --iContainerWidth: 260px;
    --iContainerHeight: 120px;
    --iImgContainerSize: 95px;
    --iTop: 19px;
    --iLeft: 135px;
    --iMarginLeft: 53px;
  }
}

@media screen and (max-width: 850px) {
  :root {
    --iContainerWidth: 228px;
    --iImgContainerSize: 110px;
    --iTop: 10px;
    --iLeft: 112px;
  }
}

@media screen and (max-width: 710px) {
  :root {
    --iContainerWidth: 180px;
    --iContainerHeight: 120px;
    --iImgContainerSize: 104px;
    --iTop: 14px;
    --iLeft: 90px;
    /* --iMarginLeft: 51px; */
  }
}

@media screen and (max-width: 605px) {
  :root {
    --iContainerWidth: 149px;
    --iContainerHeight: 89px;
    --iImgContainerSize: 80px;
    --iTop: 10px;
    --iLeft: 72px;
    --iMarginLeft: 40px;

  }
}

@media screen and (max-width: 460px) {
  :root {
    --iContainerWidth: 109px;
    --iContainerHeight: 72px;
    --iImgContainerSize: 67px;
    --iTop: 6px;
    --iLeft: 55px;
    --iMarginLeft: 34px;
  }
}
@media screen and (max-width: 380px) {
  :root {
    --iContainerWidth: 80px;
    --iContainerHeight: 54px;
    --iImgContainerSize: 51px;
    --iTop: 7px;
    --iLeft: 43px;
    --iMarginLeft: 29px;
  }
}
