/* .projectContainer {

} */

.projectContainer ul li {
  list-style-position: inside;
  list-style-type: circle;
  font-size: var(--languageContainerListFrontSize);
}

.projectContainer  {
}

.projectContainer .projectNameContainer {
  height: 65%;
  display: block;
  padding: 1px 19px;
  overflow-y: auto;
  margin: 5px 0px;
  /* align-items: center; */
  /* border: 1px solid red; */
}

.projectContainer .projectNameContainer p,
.projectNameContainer a {
  font-size: 1.1rem;
}

.projectNameContainer a:hover {
  /* background-color: green; */
  /* border-radius: 10px; */
  color: green;
}

.projectContainer .projectNameContainer strong {
  font-size: 1.3rem;
}

.projectContent {
  padding: 10px;
  border-bottom: 1px solid gray;
  border-radius: 10px;
}

/* Responsive code begin */

@media screen and (max-width: 480px) {
  .projectContent {
    margin-top: 10px;
    margin-left: 30px;
  }

  .projectContainer .projectNameContainer p,
.projectNameContainer a {
  font-size: 1.2rem;
}
}