* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background-color: aquamarine; */
}
::-webkit-scrollbar {
    width: 5px;
  }

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(174, 56, 56);
    border-radius: 10px;
  }