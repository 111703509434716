:root {
  --languageContainerMarginLeft: 40px;
  --languageContainerListFrontSize: 1.4rem;
}
/* .languageContainer {
    
} */

.languageContainer ul {
  margin-left: var(--languageContainerMarginLeft);
  margin-top: 20px;

  /* text-align: center; */
}
.languageContainer ul li {
  list-style-position: inside;
  list-style-type: circle;
  font-size: var(--languageContainerListFrontSize);
}
