.rightContentCollection {
  height: calc(96vh - var(--iContainerHeight));
  width: calc(var(--titleContainerWidth) + 472px);
  /* border: 1px solid green; */
  position: absolute;
  right: 0px;
  top: -564px;
  flex-wrap: wrap;
}

/* Responsive code begin */

@media screen and (max-width: 480px) {
  .rightContentCollection {
    width: 100%;
    height: auto;
    position: static;
    margin-bottom: 20px;
    justify-content: flex-start;
    /* border: 4px solid red; */
    /* flex-direction: column; */
    /* background-color: yellow; */
  }
}
