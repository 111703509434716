:root {
    --educationContainerHeight: 270px;
}
.educationContainer {
    height: var(--educationContainerHeight);
}

.educationContainer .percentage {
    margin-left: 30px;
}
.educationContainer .educationDetails {
    margin-left: 10px;
    font-size: 1.2rem;
}

.educationContainer .educationDetails strong {
    font-size: 1.4rem;
    margin-left: 14px;
}

.educationContainer  .first {
    margin-bottom: 10px;
    /* border: 2px solid red; */
    margin-top: 9px;
}

/* Responsive code begin */

/* Responsive Code begin */

@media screen and (max-width: 480px) {
    .educationContainer {
       height: auto;       
       padding-bottom: 15px; 
       /* padding: 10px; */
    }

.educationContainer .educationDetails {
    margin-left: 30px;
}
}